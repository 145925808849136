import Brand from '@components/Brand/Brand';
import React from 'react';
import Link from 'next/link';
import Container from '@components/Container/Container';
import PicStudioLogo from '@components/PicStudioLogo/PicStudioLogo';
import { BsInstagram, BsTiktok, BsTwitter } from 'react-icons/bs';

const navigation = {
  ai_photo_packs: [
    { name: '90s Senior Yearbook', href: '/packs/browse/clncbhi9w0006l10f5i06bveo' },
    { name: 'Dark Mode', href: '/packs/browse/cllpy2jy70053l50f24bkkfty' },
    { name: 'Light Mode', href: '/packs/browse/cllmuthkd000ajp0gthaffp74' },
    { name: 'Moody Fall', href: '/packs/browse/clmju6vcb000rmr0fcasp23hd' },
    { name: 'Gradient', href: '/packs/browse/clm45ia4f000vmg0f8g0msbce' },
    { name: 'In Bloom', href: '/packs/browse/cll32tjdm000wl90fxvndelm2' },
    { name: 'Vintage Film', href: '/packs/browse/clmjqcl53001pmm0fphjyth9p' },
    { name: '90’s Polaroid', href: '/packs/browse/cll6yddqo000bl70fh3kdxsd6' },
    { name: 'Corporate', href: '/packs/browse/cllpoz2tj000bl80fj6sohcaa' },
    { name: 'Citylife', href: '/packs/browse/cllpp0ozp000xmn0fmnj348hv' },
    { name: 'Noir Zone', href: '/packs/browse/cllmqudo0000wmg0fj0bjulzx' },
    { name: 'Terracotta', href: '/packs/browse/clm5bfzn9000kie0f47j1yhk3' },
    { name: 'Wallpaper', href: '/packs/browse/cllqtd6nb000bic0fhh0v19cj' },
    { name: 'Red', href: '/packs/browse/clmf4k3lw0002l50ffzack3s6' },
    { name: 'Gold Portra', href: '/packs/browse/clmf5dorq0001l30fna0tx211' },
    { name: 'Arctic Fashion', href: '/packs/browse/clmf50bcb000hlj0ftplshan1' },
    { name: 'Grand Theft Portraits', href: '/packs/browse/clmo4psbq000llc0f0xmq3z4a' },
    { name: 'Alley Cat', href: '/packs/browse/clkowg6nt0001ld0f3ec09nlp' },
    { name: 'Tropical', href: '/packs/browse/cllppoq42002fl80guibdujld' },
    { name: 'Yearbook Throwback', href: '/packs/browse/clmkz695c000dic0f0shis5ue' },
    { name: 'Random Eras', href: '/packs/browse/clmkoyn3i000wjm0fqdrmh3qx' },
    { name: 'Woodlands', href: '/packs/browse/clmju9but0013mr0ftlus2q9e' },
    { name: 'Moody Blue', href: '/packs/browse/clmju2y9h0001ml0fsmj9epcf' },
    { name: 'Homelife', href: '/packs/browse/cllpp3upm001rmp0gxtxzmc3r' },
    { name: 'Analog Film', href: '/packs/browse/clmjqbkbq0023ld0fvhgk860u' },
    { name: 'Real Estate Agent', href: '/packs/browse/cllpr6wuo0013la0f92vh43gw' },
    { name: 'Athletic Trainer', href: '/packs/browse/cllps9hue000vmr0fftsigqy8' },
    { name: 'Lab Coat', href: '/packs/browse/cllproamd000cjq0fyb1f1vmp' },
    { name: 'Scrubs', href: '/packs/browse/cllprmv010024jm0fe9j0e0by' },
    { name: 'Teacher', href: '/packs/browse/cllprfbjm0015jm0fvvu9g4mx' },
    { name: 'Venture Capitalist', href: '/packs/browse/cllpqui73000pmg0f7om9nrd9' },
    { name: 'Corporate Lawyer', href: '/packs/browse/cllpqr4ah000dmg0fvdtmxsl9' },
    { name: 'Founder: Dark', href: '/packs/browse/cllqxv95r000vjw0ga4p8mv04' },
    { name: 'Founder: Light', href: '/packs/browse/cllqy5yp8000xl70f358q6mvc' },
    { name: 'Corporate 1990', href: '/packs/browse/clloca59g000ml10fwlwhhwg0' },
    { name: 'Lifestyle 1990', href: '/packs/browse/cllocaxk6000ml60gq4xg22dh' },
    { name: 'Corporate 1980', href: '/packs/browse/cllpfbs0l000hmk0f69oixhzh' },
    { name: 'Lifestyle 1980', href: '/packs/browse/cllpf51xb001ll70frk85beu1' },
    { name: '1970 Corporate', href: '/packs/browse/cllvory060001lb0f9j6248qj' },
    { name: 'Lifestyle 1970', href: '/packs/browse/cllpf1s640007l70fsvby8sh9' },
    { name: '1970 Workplace', href: '/packs/browse/clnaou79s0001mb0fad59kem8' },
    { name: '1960 Corporate', href: '/packs/browse/cllpffziw000xld0fkwlcfh9x' },
    { name: '1960 Lifestyle', href: '/packs/browse/cllpf847h001tju0gyk747509' },
    { name: 'HESHER 1990', href: '/packs/browse/cllpyxryc0023l30fgposhpug' },
    { name: 'SKATER 1990', href: '/packs/browse/cllpz2xp1002zl30frxf9wyun' },
    { name: 'Y2K', href: '/packs/browse/cllpz7t9x001hkw0fthqiyhx7' },
    { name: 'y2k Emo 4vr', href: '/packs/browse/cllpz9zh0003bl30f3fbq8706' },
    { name: 'y2k Preppy', href: '/packs/browse/cllpzeo7u003rl50gytcb18w9' },
    { name: 'y2k 2002', href: '/packs/browse/cllpzo20h000vmj0fg09zg2c5' },
    { name: '1999', href: '/packs/browse/cllpzr7q6000lmj0fl7aplp0k' },
    { name: 'Y2K Razr', href: '/packs/browse/cllpztn51000bkw0fdwh16u0i' },
    { name: 'SWING 1990', href: '/packs/browse/cllq27c5z0001jv0fvdla3616' },
    { name: 'WORKPLACE 1940', href: '/packs/browse/cllsfvlww002tld0fvnj12jv5' },
    { name: 'Lifestyle 1940', href: '/packs/browse/cllsfw47g002vld0fqawkjfow' },
    { name: 'Workplace 1930', href: '/packs/browse/cllsfx67z0001ma0fz6jn51ws' },
    { name: 'Lifestyle 1930', href: '/packs/browse/cllsfxklk0001l10fk09xgdef' },
    { name: 'Lifestyle 1920', href: '/packs/browse/cllsfyac20003ma0f58x517jv' },
    { name: 'Workplace 1920', href: '/packs/browse/cllsfyrg50003l10fzdpjch0b' },
    { name: 'Sahara Desert', href: '/packs/browse/clmf6j5rg0002lb0fg4qbmmcc' },
    { name: 'Rainforest', href: '/packs/browse/clmf6k8kj0002l50g4cvuli3u' },
    { name: 'Ancient Ruins', href: '/packs/browse/clmf6kund000wl60f9psizip0' },
    { name: 'Seaside Cliff', href: '/packs/browse/clmf6l7l90004l50g7qr0rr1k' },
    { name: 'Cosmic Starfield', href: '/packs/browse/clmf6ll9g0004lb0folv1yooa' },
    { name: 'Golden Desert', href: '/packs/browse/cllpq577s000jjr0fhtfw3u4d' },
    { name: 'Tintype', href: '/packs/browse/clkl9c6vf000fjl0f61c0duxp' },
    { name: 'Hurrell', href: '/packs/browse/cll3s0xvq000vjj0fputnx6m5' },
    { name: 'Cyberpunk', href: '/packs/browse/cllvtwl9q002al10f43a6mfvo' },
    { name: 'Steampunk', href: '/packs/browse/cllvt57nr000cl70f6lyz2lku' },
    { name: "Five O'Clock", href: '/packs/browse/cll36jzew0001l20filczux7q' },
    { name: 'Pro Portraits', href: '/packs/browse/clkl9byyn0005jl0fasp4s0uz' },
    { name: 'Purple Mode', href: '/packs/browse/cllmv3kgf0008l40f2ht8bksv' },
    { name: 'Desert Echoes', href: '/packs/browse/cll3344qn001il90fbkziewo0' },
    { name: '80’s Penny', href: '/packs/browse/cll3yu0080001mh0ggs7ff3qk' },
    { name: 'Anderson’s Quirky', href: '/packs/browse/cllmmkil5000zla0fwrwqf600' },
    { name: 'Anderson’s Charm', href: '/packs/browse/cllmpdxsf0001l60f9jlux85q' },
    { name: 'Wes Stock', href: '/packs/browse/clln5i9r3002cmn0fb547vcka' },
    { name: 'Early November', href: '/packs/browse/cllpqauqm000ql50fg767dob5' },
    { name: 'Simplified Studio', href: '/packs/browse/clmf6quib001fmg0f1vq596kk' },
    { name: 'Cyberpunk 2030', href: '/packs/browse/cln4bcj7d0001lg0fq9t11h5j' }
  ],
  pages: [
    { name: 'AI Photo Generator', href: '/ai-photo-generator' },
    { name: 'Photo Packs', href: '/packs/browses' },
    { name: 'Reviews & Examples', href: '/pricing' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Gift AI Photos', href: '/pricing?gift=true' },
    { name: 'Refer A Friend', href: '/refer' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Support', href: '/support' }
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' }
  ],
  ourFriends: [
    { name: 'Prompt Hunt', href: 'https://prompthunt.com/' },
    { name: 'Prompt Templates', href: 'https://www.prompthunt.com/themes' },
    { name: 'Karaoke Smash', href: 'https://www.karaokesmash.com/' },
    { name: 'Habit Coach', href: 'https://habitcoach.ai/' }
  ],
  social: [
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@picstudioai',
      icon: BsTiktok
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/picstudioai/',
      icon: BsInstagram
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/picstudioai',
      icon: BsTwitter
    }
  ]
};

export default function Example() {
  return (
    <Container>
      <footer className="w-full" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className=" mx-auto w-full pt-10 pb-24">
          <div className="grid xl:grid-cols-5 xl:gap-8 gap-10">
            <div className="bg-blue-500/0 space-y-4 text-xs leading-5">
              <div className="flex flex-col items-center md:items-start">
                <PicStudioLogo className="mb-6" />
                <div className="mb-1 text-muted-foreground">A product by</div>
                <Link href="https://prompthunt.com" target="_blank">
                  <Brand className="fill-primary" width={128} />
                </Link>
                <p className="pt-4 text-primary/50">&copy; 2023 Prompt Hunt Inc. All rights reserved.</p>
              </div>
              <div className="flex space-x-6 justify-center md:justify-start">
                {navigation.social.map(item => (
                  <Link key={item.name} href={item.href} className="text-muted-foreground hover:text-primary">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6 stroke-none" aria-hidden="true" />
                  </Link>
                ))}
              </div>
            </div>
            <div className=" bg-red-500/0 xl:col-span-3 space-y-5">
              <h3 className="text-sm font-semibold leading-6 text-current">AI Photo Packs</h3>
              <ul role="list" className="bg-red-500/0 gap-2 grid md:grid-cols-3 lg:grid-cols-3">
                {navigation.ai_photo_packs.map(item => (
                  <li key={item.name}>
                    <Link href={item.href} className="text-sm leading-6 text-muted-foreground hover:text-primary">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-purple-500/0 grid md:grid-cols-3 xl:grid-cols-1 h-fit gap-10">
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">Site Map</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.pages.map(item => (
                    <li key={item.name}>
                      <Link href={item.href} className="text-sm leading-6 text-muted-foreground hover:text-current">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">Legal</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.legal.map(item => (
                    <li key={item.name}>
                      <Link href={item.href} className="text-sm leading-6 text-muted-foreground hover:text-current">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">More AI Sites</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.ourFriends.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-muted-foreground hover:text-current"
                        target="_blank"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}
